(function($) {
	"use strict";
	
	// Let's go!
	$(document).ready(function() {

		// Home slider
		$('.flexslider-home').flexslider({
			animation: "fade",
			slideshow: true,
			slideshowSpeed: 4000,
			animationSpeed: 600,
			smoothHeight: false,
			controlsContainer: ".flex-container-home",
			directionNav: true,
			controlNav: false,
			useCSS: false		
		});

		// Testimonials slider
		$('.flexslider-testimonials').flexslider({
			animation: "slide",
			slideshow: true,
			slideshowSpeed: 4000,
			animationSpeed: 600, 
			smoothHeight: true,
			controlsContainer: ".flex-container",
			directionNav: false,
			controlNav: true,
			useCSS: false			
		});
		
		// Prettyphoto galleries
		$(".gal-img a[data-rel^='prettyPhoto']").prettyPhoto({
			animation_speed: 'normal',
			autoplay_slideshow: true,
			slideshow: 3000
		});
		
		
		// Hide FAQ sections initially
		$('.faq-section').hide();

		// Show first FAQ title
		$('h5.faq-title:first').addClass('active').next().show();

		// FAQ title onclick
		$('h5.faq-title').on('click',function(){
			if( $(this).next().is(':hidden') ) {
				$('h5.faq-title').removeClass('active').next().slideUp(); 
				$(this).toggleClass('active').next().slideDown();
			} else {
				$('h5.faq-title').removeClass('active').next().slideUp();
			}
			return false;
		});

		// Hide scrollup initially
		$(".scrollup").hide();

		// Scroll up functionality
		$(window).on('scroll', function() {
			if ($(this).scrollTop() > 400) {
				$('.scrollup').fadeIn();
			} else {
				$('.scrollup').fadeOut();
			}
		});

		// Scroll to top
		$("a.scrolltop[href^='#']").on('click', function(e) {
			e.preventDefault();
			var hash = this.hash;
			$('html, body').stop().animate({scrollTop:0}, 1000, 'easeOutExpo');
		});

	});
})(jQuery);

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
